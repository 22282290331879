import styled from 'styled-components';

const Content = styled.div`
 padding: 2rem 0;
  ${({ theme }) => `
    ${theme.breakPoints.up('sm')} {
      padding: 3rem 0;
    }
  `}
`

export default Content;